import { useEffect } from 'react';
import { NextPage } from 'next';
import { ANALYTICS_PAGE_ID } from '@config/logging';
import { computePageViewData, sendPageView } from '@helpers/logging/analytics/page-view-event';
import { geometryGetType } from '@selectors/geometry';
import { useEventBus } from '@hooks/logging';
import { useNavigator } from '@hooks/routing';
import { useStorage } from '@hooks/storage';
import { useTypedSelector } from '@hooks/store';
import { PublicOnly } from '@managers/access-control/public-only';
import { ClientLandingScene } from '@scenes/client-landing';
import { LayoutWidget } from '@widgets/landing/landing-client/layout';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
const HomePage: NextPage = () => {
  const title = 'Главная страница';
  const eventBus = useEventBus();
  const navigator = useNavigator();
  const storage = useStorage();
  const geometryType = useTypedSelector(geometryGetType);
  useEffect(() => {
    const _sendPageView = async () => {
      sendPageView(eventBus, {
        ...(await computePageViewData({
          navigator,
          storage,
          geometryType
        })),
        pageId: ANALYTICS_PAGE_ID.MAIN
      });
    };
    _sendPageView();
  }, [eventBus, geometryType, navigator, storage]);
  return _jsx(PublicOnly, {
    children: _jsx(LayoutWidget, {
      headerLogoImageSrc: "/images/logo-header.svg" //
      ,
      footerLogoImageSrc: "/images/logo-footer.svg",
      noPaddings: true,
      children: _jsx(ClientLandingScene, {
        title: title
      })
    })
  });
};
export default HomePage;